/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { getTokens, storeTokens } from '../common/localStorageHelper';
import { getApi, getOidcApi } from '../common/requestHelper';
import { ClickstreamAnalytics } from '@aws/clickstream-web';
import {
  GrantTokenDto,
  GrantTokenResponseDto,
  UserApi,
} from '@ink-ai/insight-service-sdk';
import { isAxiosError } from 'axios';
import { configuration } from './configuration';
import { RootState } from '.';

export enum AuthType {
  AMAZON_FED = 'AMAZON_FED',
}

export type AuthState = {
  username?: string;
  userFullName?: string;
  userEmail?: string;
  userId?: string;
  loggedIn: boolean;
  authType?: AuthType;
  loading: boolean;
  grantTokenLoading: boolean;
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  expiresAt?: number;
  instanceId: string;
  loginErrorMessageOpen: boolean;
  loginErrorMessage?: string;
};

const initialState: AuthState = {
  username: undefined,
  loggedIn: false,
  loading: false,
  authType: undefined,
  loginErrorMessageOpen: false,
  instanceId: uuidv4(),
  grantTokenLoading: false,
};

const getInitState = (): AuthState => {
  const tokens = getTokens();
  return {
    ...initialState,
    accessToken: tokens.access_token,
    expiresAt: tokens.expires_at,
    idToken: tokens.id_token,
    refreshToken: tokens.refresh_token,
  };
};

export type grantTokenInput = {
  grant_type: 'authorization_code' | 'refresh_token';
  code?: string;
  refresh_token?: string;
};

export const grantToken = createAsyncThunk(
  'auth/grantToken',
  async (body: GrantTokenDto, { dispatch, getState }) => {
    const state = getState() as RootState;
    dispatch(auth.actions.changeLoading(true));
    try {
      const response = await getOidcApi().grantToken({
        ...body,
        redirect_uri: `${state.app.addInHost}/oidc.html`,
      });
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      if (isAxiosError(error) && error.response) {
        dispatch(
          auth.actions.setLoginErrorMessage(error.response.data.message),
        );
      } else {
        dispatch(auth.actions.setLoginErrorMessage(error.message));
      }
      console.log(error);
      throw error;
    }
  },
);

export const login = createAsyncThunk('auth/Login', async (_, { dispatch }) => {
  const userApi = await getApi(UserApi);
  try {
    const { data } = await userApi.login();
    dispatch(configuration.actions.updateUserConfiguration(data.configuration));
    return data;
  } catch (error: any) {
    dispatch(auth.actions.signOut());
    if (isAxiosError(error) && error.response) {
      dispatch(auth.actions.setLoginErrorMessage(error.response.data.message));
    } else {
      dispatch(auth.actions.setLoginErrorMessage(error.message));
    }
    console.error(error);
    throw error;
  }
});

export const auth = createSlice({
  name: 'auth',
  initialState: getInitState(),
  reducers: {
    setLoginErrorMessage(state, { payload }: PayloadAction<string>) {
      state.loginErrorMessageOpen = true;
      state.loginErrorMessage = payload;
    },
    closeLoginErrorMessage(state) {
      state.loginErrorMessageOpen = false;
    },
    inputUsername(state, { payload }: PayloadAction<string>) {
      state.username = payload;
    },
    changeLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    signOut() {
      storeTokens(null);
      try {
        ClickstreamAnalytics.setUserId(null);
      } catch (error: any) {
        console.error(error);
      }
      return initialState;
    },
    tokenGranted(state, { payload }: PayloadAction<GrantTokenResponseDto>) {
      state.accessToken = payload.access_token;
      state.idToken = payload.id_token;
      state.refreshToken = payload.refresh_token ?? state.refreshToken;
      state.expiresAt = payload.expires_at;
      storeTokens(payload);
    },
    setAuth(_state, { payload }: PayloadAction<AuthState>) {
      console.log('setAuthStore', payload);
      return payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(grantToken.pending, (state) => {
      state.grantTokenLoading = true;
    });
    builder.addCase(grantToken.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.grantTokenLoading = false;
      state.accessToken = payload.access_token;
      state.idToken = payload.id_token;
      state.refreshToken = payload.refresh_token ?? state.refreshToken;
      state.expiresAt = payload.expires_at;
      storeTokens(payload);
    });
    builder.addCase(grantToken.rejected, (state) => {
      state.loading = false;
      state.grantTokenLoading = false;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userId = payload.uuid;
      state.username = payload.username;
      state.userEmail = payload.email;
      state.userFullName = payload.name;
      state.loggedIn = true;
      try {
        ClickstreamAnalytics.setUserId(payload.uuid);
      } catch (error: any) {
        console.error(error);
      }
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
    });
  },
});
