/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  CardContent,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  article,
  startArticleGeneration,
} from '@ink-ai/portal/reducers/article';
import { RoundedButton } from '@ink-ai/portal/common/components/Buttons';
import { StatusDisplay } from './StatusDisplay';
import StopIcon from '@mui/icons-material/Stop';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { CollapseCard } from '../../cards/CollapseCard';
import { ConnectorApi } from '@ink-ai/insight-service-sdk';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import { useAsyncData } from '@ink-ai/portal/common/hooks/useAsyncData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ArticleGenerate = () => {
  const dispatch = useDispatch<any>();
  const articleState = useSelector((state: RootState) => state.article);
  const { currentStep, steps, selectedConnectors, inputValue } = articleState;

  const { data } = useAsyncData(async () => {
    const connectorApi = await getApi(ConnectorApi);
    return await connectorApi.listConnectors();
  }, []);
  const connectors = data?.data?.connectors || [];

  const handleConnectorChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    dispatch(
      article.actions.updateSelectedConnectors(
        typeof value === 'string' ? value.split(',') : value,
      ),
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(article.actions.updateInputValue(event.target.value));
  };

  const handleGenerateClick = () => {
    dispatch(
      startArticleGeneration({
        text: inputValue,
        connectors: connectors
          .filter(({ uuid }) => selectedConnectors.includes(uuid))
          .map(({ lambdaName }) => ({
            lambdaName,
            filters: [],
          })),
      }),
    );
    console.log('Generating with:', { inputValue, selectedConnectors });
  };

  const renderConnectorNames = (selected: string[]) => {
    return selected
      .map((uuid) => {
        const connector = connectors.find((c) => c.uuid === uuid);
        return connector ? connector.name : uuid;
      })
      .join(', ');
  };

  return (
    <Box>
      <TextField
        fullWidth
        label="Input for Generation"
        value={inputValue}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 1 }}>
        <InputLabel id="connector-select-label">Select Connectors</InputLabel>
        <Select
          labelId="connector-select-label"
          id="connector-select"
          multiple
          value={selectedConnectors}
          onChange={handleConnectorChange}
          input={<OutlinedInput label="Select Connectors" />}
          renderValue={renderConnectorNames}
          MenuProps={MenuProps} // limit the height of the menu
        >
          {connectors.map((c) => (
            <MenuItem key={c.uuid} value={c.uuid}>
              <Checkbox checked={selectedConnectors.indexOf(c.uuid) > -1} />
              <ListItemText primary={c.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="py-3">
        <RoundedButton
          variant="contained"
          fullWidth={true}
          size="large"
          onClick={handleGenerateClick}
          disabled={!inputValue || articleState.isGenerating}
        >
          <AutoFixHighOutlinedIcon className="mr-1" />
          Compose
        </RoundedButton>
      </div>
      {articleState.steps.length > 0 && (
        <CollapseCard open={true}>
          <CardContent className="card-content">
            <div>
              <StatusDisplay steps={steps} currentStep={currentStep} />
            </div>
            <div>
              {articleState.isGenerating && (
                <Button
                  color="error"
                  fullWidth={true}
                  onClick={() => dispatch(article.actions.stopGenerating())}
                  sx={{ mt: 1 }}
                >
                  <StopIcon className="mr-1" />
                  Stop generating
                </Button>
              )}
            </div>
          </CardContent>
        </CollapseCard>
      )}
    </Box>
  );
};
