/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApi } from '../common/requestHelper';
import { ReferenceApi } from '@ink-ai/insight-service-sdk';
import axios, { AxiosProgressEvent } from 'axios';

export type ReferenceState = {
  fileName: string;
  loading: boolean;
  deleting: boolean;
  fileList: ReferenceItem[];
  progress: number;
  selectedFileDetail: any;
  selectedFileIds: string[];
  referenceErrorMessageOpen: boolean;
  referenceErrorMessage?: string;
};

const initialState: ReferenceState = {
  fileName: '',
  loading: false,
  deleting: false,
  fileList: [] as ReferenceItem[],
  progress: 0,
  selectedFileDetail: null as any,
  selectedFileIds: [] as string[],
  referenceErrorMessageOpen: false,
};

export interface ReferenceItem {
  id: string;
  name: string;
  createdAt: number;
  checked: boolean;
}

export const fetchReferenceList = createAsyncThunk(
  'reference/fetchReferenceList',
  async () => {
    const referenceApi = await getApi(ReferenceApi);
    const response = await referenceApi.listReferenceDocuments();

    const sortedReferences = response.data.references
      .map((file: any) => ({
        id: file.id,
        name: file.name,
        createdAt: file.createdAt,
        checked: false,
      }))
      .sort((a, b) => b.createdAt - a.createdAt);

    return sortedReferences;
  },
);

export const uploadReference = createAsyncThunk(
  'reference/uploadReference',
  async (
    {
      file,
      onUploadProgress,
    }: {
      file: File;
      onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
    },
    { dispatch },
  ) => {
    dispatch(reference.actions.startLoading());
    const referenceApi = await getApi(ReferenceApi);

    const response = await referenceApi.getS3PresignedUrl({
      filename: file.name,
    });

    if (!response.data.url) {
      throw new Error('Presigned URL is undefined');
    }

    const presignedUrl: string = response.data.url;
    const fileUuid = response.data.id;

    try {
      await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
        },
        onUploadProgress,
      });
      await dispatch(parseReference(fileUuid));
      dispatch(fetchReferenceList());
      return file.name;
    } catch (error: any) {
      dispatch(fetchReferenceList());
      throw new Error(
        `Failed to upload file: ${error.response?.statusText || error.message}`,
      );
    }
  },
);

export const getReference = createAsyncThunk(
  'reference/getReference',
  async (uuid: string) => {
    const referenceApi = await getApi(ReferenceApi);
    const response = await referenceApi.getReferenceDocument(uuid);
    return response.data;
  },
);

export const deleteReference = createAsyncThunk(
  'reference/deleteReference',
  async (uuid: string, { dispatch }) => {
    const referenceApi = await getApi(ReferenceApi);
    await referenceApi.deleteReferenceDocument(uuid);
    dispatch(fetchReferenceList());
  },
);

export const parseReference = createAsyncThunk(
  'reference/parseReference',
  async (uuid: string) => {
    const referenceApi = await getApi(ReferenceApi);
    const response = await referenceApi.parseDocument(uuid);
    return response.data;
  },
);

export const reference = createSlice({
  name: 'reference',
  initialState,
  reducers: {
    setReferenceErrorMessage(state, { payload }: PayloadAction<string>) {
      state.referenceErrorMessageOpen = true;
      state.referenceErrorMessage = payload;
    },
    closeReferenceErrorMessage(state) {
      state.referenceErrorMessageOpen = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    clearReference: () => {
      return initialState;
    },
    toggleReferenceCheck: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      const file = state.fileList[index];
      file.checked = !file.checked;
      if (file.checked) {
        state.selectedFileIds.push(file.id);
      } else {
        state.selectedFileIds = state.selectedFileIds.filter(
          (id) => id !== file.id,
        );
      }
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadReference.fulfilled, (state, action) => {
      state.loading = false;
      state.fileName = action.payload;
    });
    builder.addCase(uploadReference.rejected, (state, action) => {
      state.loading = false;
      console.error(action.error);
    });
    builder.addCase(fetchReferenceList.fulfilled, (state, action) => {
      state.fileList = action.payload;
    });
    builder.addCase(fetchReferenceList.rejected, (_, action) => {
      console.error(action.error);
    });
    builder.addCase(deleteReference.pending, (state) => {
      state.deleting = true;
    });
    builder.addCase(deleteReference.fulfilled, (state) => {
      state.loading = false;
      state.deleting = false;
    });
    builder.addCase(deleteReference.rejected, (state, action) => {
      state.deleting = false;
      state.loading = false;
      console.error(action.error);
    });
    builder.addCase(getReference.fulfilled, (state, action) => {
      state.selectedFileDetail = action.payload;
    });
    builder.addCase(getReference.rejected, (_, action) => {
      console.error(action.error);
    });
  },
});

export const referenceActions = reference.actions;
